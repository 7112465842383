import React, {Component, Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {APP_URL_BASE} from './utilities/Constants';
import './../src/css/Styles.css'
import './../src/css/icofont.css'

const SetPassword = React.lazy(() => import('./pages/SetPassword'));
const SetPwdSuccess = React.lazy(() => import('./pages/SetPwdSuccess'));
const SetPwdExpired = React.lazy(() => import('./pages/SetPwdExpired'));
const ResetLinkExpired = React.lazy(() => import('./pages/ResetLinkExpired'));
const ResetLinkMail = React.lazy(() => import('./pages/ResetLinkMail'));
const ResetLogin = React.lazy(() => import('./pages/ResetLogin'));
const ResetPassword = React.lazy(() => import('./pages/ResetPassword'));
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));
const Login = React.lazy(() => import('./pages/Login'));
const Trmilogin = React.lazy(() => import('./pages/Trmilogin'));
const Users = React.lazy(() => import('./pages/users/Users'));
const Page404 = React.lazy(() => import('./pages/Page404'));
const RegistrationPage = React.lazy(() => import('./pages/Registration'));
const InviteScreen = React.lazy(() => import('./pages/users/Brokers/Invite/InviteScreen'))
const ELDDetails = React.lazy(() => import('./pages/users/Brokers/Invite/ELDDetails'))
const ThankScreen = React.lazy(() => import('./pages/users/Brokers/Invite/ThankScreen'))
const InitRegistration = React.lazy(() => import('./pages/InitRegistration'));
const BrokerRegistration = React.lazy(() => import('./pages/BrokerRegistration'));
const CarrierRegistration = React.lazy(() => import('./pages/CarrierRegistration'));
const ShipperRegistration = React.lazy(() => import('./pages/ShipperRegistration'));
const BrokerRating = React.lazy(() => import('./pages/BrokerRating'));
const CarrierRating = React.lazy(() => import('./pages/CarrierRating'));

const loading = (
    <div className="row no-gutters align-items-center gig-login">
        <div className="col-md-3 shadow p-5 bg-white rounded">
            <div className="row no-gutters">
                <div className="col text-center">
                    <img src={APP_URL_BASE + "assets/images/logo_black.png"} alt="logo" className="logo"/>
                </div>
            </div>
            <div className="row no-gutters">
                <div className="col text-center pt-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

class App extends Component {
    // constructor(props){
    //   super(props)
    // }
    render = () => {
        return (
            <Suspense fallback={loading}>
                <Switch>
                    <Route exact path="/login" render={(routeProps) => {
                        return <Login routeProps={routeProps}/>
                    }}/>
                     <Route exact path="/trmilogin" render={(routeProps) => {
                        return <Trmilogin routeProps={routeProps}/>
                    }}/>
                    <Route exact path="/forgotpassword" render={(routeProps) => {
                        return <ForgotPassword routeProps={routeProps}/>
                    }}/>
                    <Route exact path="/resetpassword" render={(routeProps) => {
                        return <ResetPassword routeProps={routeProps}/>
                    }}/>
                    <Route exact path="/resetnext" render={(routeProps) => {
                        return <ResetLogin routeProps={routeProps}/>
                    }}/>
                    <Route exact path="/resetmail" render={(routeProps) => {
                        return <ResetLinkMail routeProps={routeProps}/>
                    }}/>
                    <Route exact path="/resetexpiry" render={(routeProps) => {
                        return <ResetLinkExpired routeProps={routeProps}/>
                    }}/>
                    <Route exact path="/initpassword" render={(routeProps) => {
                        return <SetPassword routeProps={routeProps}/>
                    }}/>
                    <Route exact path="/setsuccess" render={(routeProps) => {
                        return <SetPwdSuccess routeProps={routeProps}/>
                    }}/>
                    <Route exact path="/setexpiry" render={(routeProps) => {
                        return <SetPwdExpired routeProps={routeProps}/>
                    }}/>
                    <Route path="/users" render={(routeProps) => {
                        return <Users routeProps={routeProps}/>
                    }}/>
                    <Route exact path="/registration" render={(routeProps) => {
                        return <RegistrationPage routeProps={routeProps}/>
                    }}/>
                    <Route exact path="/invite" render={(routeProps) => {
                        return <InviteScreen routeProps={routeProps}/>
                    }}/>
                    <Route exact path="/elddetails" render={(routeProps) => {
                        return <ELDDetails routeProps={routeProps}/>
                        console.log("Route", routeProps);
                    }}/>
                    <Route exact path="/thankyou" render={(routeProps) => {
                        return <ThankScreen routeProps={routeProps}/>
                    }}/>
                    <Route exact path="/initregistration" render={(routeProps) => {
                        return <InitRegistration routeProps={routeProps}/>
                    }}/>
                    <Route exact path="/brokerregistration" render={(routeProps) => {
                        return <BrokerRegistration routeProps={routeProps}/>
                    }}/>
                    <Route exact path="/carrierregistration" render={(routeProps) => {
                        return <CarrierRegistration routeProps={routeProps}/>
                    }}/>
                    <Route exact path="/shipperregistration" render={(routeProps) => {
                        return <ShipperRegistration routeProps={routeProps}/>
                    }}/>
                     <Route exact path="/brokerrating" render={(routeProps) => {
                        return <BrokerRating routeProps={routeProps}/>
                    }}/>
                     <Route exact path="/carrierrating" render={(routeProps) => {
                        return <CarrierRating routeProps={routeProps}/>
                    }}/>
                    <Route exact path="/">
                        <Redirect to="/login"/>
                    </Route>
                    <Route path="/*" render={(routeProps) => {
                        return <Page404 routeProps={routeProps}/>
                    }}/>
                </Switch>
            </Suspense>
            // <h1>App1</h1>
        );
    }
}

export default App;