import { DELETE, GET, POST, UPLOAD, PUT } from './ApiContract';

/************* LOADS *****************/
export const LoadsListService = (payload) => {
    return POST('clients/loads/broker/list', payload);
}
export const CarrierLoadsListService = (payload) => {
    return POST('clients/loads/carrier/list', payload);
}
export const ShipperLoadsListService = (payload) => {
    return POST('clients/loads/shipper/list', payload);
}

export const AssignLoadCarrier = (payload) => {
    return POST('clients/loadprocess/broker/assigncarrier', payload);

}

export const AssignLoadTruckCarrier = (payload) => {
    return POST('clients/loadprocess/carrier/assigntruck', payload);
}

export const AddPaymentService = (payload) => {
    return POST('clients/loadprocess/broker/payment', payload);
}

export const AssignDriverService = (payload) => {
    return POST('clients/loadprocess/carrier/assigndriver', payload);
}
export const AcceptLoadCarrier = (payload) => {
    return POST('clients/loadprocess/carrier/accept', payload);

}
export const RejectLoadCarrier = (payload) => {
    return POST('clients/loadprocess/carrier/reject', payload);

}
export const DownloadPodService = (payload) => {
    return GET('clients/loadprocess/pod/download', payload);
}

export const DeliveredUploadService = (payload) => {
    return UPLOAD('clients/loadprocess/carrier/deliver', payload);

}
export const BrokerDeliveredService = (payload) => {
    return UPLOAD('clients/loadprocess/broker/deliver', payload);

}
export const DeleteLoadService = (payload) => {
    return DELETE('clients/loaddata/delete', null, payload);

}
export const DispatchLoadService = (payload) => {
    return POST('clients/loadprocess/carrier/dispatch', payload);
}
export const CancelLoadService = (payload) => {
    return POST('clients/loadprocess/carrier/cancel', payload);
}
export const BrokerDispatchLoadService = (payload) => {
    return POST('clients/loadprocess/broker/dispatch', payload);

}
export const BrokerInactiveLoadList = (payload) => {
    return POST('clients/loads/broker/inactivelist', payload);
}
export const CarrierInactiveLoadList = (payload) => {
    return POST('clients/loads/carrier/inactivelist', payload);
}
export const ShipperInactiveLoadList = (payload) => {
    return POST('clients/loads/shipper/inactivelist', payload);
}

export const LoadsAdd = (payload) => {
    return POST('clients/loaddata/broker/add', payload);
}
export const LoadsEdit = (payload) => {
    return POST('clients/loaddata/broker/edit', payload);
}
export const GetLoadsById = (payload) => {
    return GET('clients/loads/get', payload);
}
export const CarrierCategory = (payload) => {
    return GET('clients/carriers/all', payload);
}
export const ShipperCategory = (payload) => {
    return GET('clients/shippers/all', payload);
}
export const AssetCategory = (payload) => {
    return GET('clients/assets/allforcarrier', payload);
}
export const AssetTruckCategory = (carrierId, payload) => {
    return GET('clients/assets/all?carrierId=' + carrierId, payload);
}
export const DriversCategory = (carrierId, payload) => {
    return GET('clients/drivers/all', payload);
}
export const LanesListService = (payload) => {
    return POST('clients/lanes/list', payload);
}
export const LanesAdd = (payload) => {
    return POST('clients/lanes/add', payload);
}
export const LanesEdit = (payload) => {
    return POST('clients/lanes/edit', payload);
}
export const GetCarrierLanesById = (payload) => {
    return GET('clients/lanes/carrierlane/get', payload);
}
export const SearchLoadsServices = (payload) => {
    return POST('clients/loadboard/carrier/searchloads', payload);
}
export const SearchTrucksServices = (payload) => {
    return POST('clients/loadboard/broker/searchtrucks', payload);
}
export const TextOrginService = (origin, payload) => {
    return GET('common/geocities/search?text=' + origin, payload);
}
export const TextXOrginService = (origin, payload) => {
    return GET('common/geocities/xsearch?text=' + origin, payload);
}
export const consigneeZipService = (zip, payload) => {
    return GET('common/geocities/getzip?text=' + zip, payload);
}


export const AssignLoadBoardCarrier = (payload) => {
    return POST('clients/loadboard/assign', payload);
}

export const PostCarrierTruckService = (payload) => {
    return POST('clients/lanes/postcarriertruck', payload);

}

/************** Availability **************/

export const AvailabilityListService = (payload) => {
    return POST('clients/posttruck/list', payload);
}
export const AvailabilityAddService = (payload) => {
    return POST('clients/loadboard/posttruck/add', payload);
}
export const GettrucksById = (payload) => {
    return GET('clients/loadboard/posttruck/get', payload);
}
export const AvailabilityEditService = (payload) => {
    return POST('clients/loadboard/posttruck/edit', payload);
}
export const AvailabilityDeleteService = ( payload) => {
    return PUT('clients/loadboard/posttruck/delete', null, payload);
}
export const SearchLoadService = ( payload) => {
    return POST('clients/loadboard/searchloads', payload); 
}
export const TruckListService = (payload) => {
    return GET('clients/loadboard/posttruck/list', payload);
}

// ################## LoadBoard Services ###########
export const LoadboardAdd = (payload) => {
    return POST('clients/loadboard/postload/add', payload);
}
export const LoadBoardEdit = (payload) => {
    return POST('clients/loadboard/postload/edit', payload);
}
export const DeleteLoadboardService = (payload) => {
    return PUT('clients/loadboard/postload/delete', null, payload);
}
export const GetLoadbordId = (payload) => {
    return GET('clients/loadboard/postload/get', payload);
}
export const SearchTruckLoad = (payload) => {
    return POST('clients/loadboard/searchtrucks', payload);
}
export const GetLoadbordList = (payload) => {
    return GET('clients/loadboard/postload/list', payload);
}
export const GetCarrierByIdService = (payload) => {
    return GET('clients/carriers/get', payload);
}

export const AssetLengthService = (cid1, payload) => {
    return GET('clients/assettypelength/get?assetTypeId=' + cid1, payload);
}
export const AssetTypeListService = (payload) => {
    return GET('clients/assettype/list', payload);
}
