import {GET, POST} from './ApiContract';

export const LoginService = (payload, urlParams) => {
    return POST('users/gateway/trmlogin', payload, urlParams);
}

export const LoginiService = (payload, urlParams) => {
    return POST('users/gateway/trmilogin', payload, urlParams);
}

export const UserInfoService = (payload) => {
    return GET('users/get', payload);
}

export const UserMenusService = (payload) => {
    return GET('users/trmmenus', payload);
}

export const ChangePasswordService = (payload) => {
    return POST('users/trmchangepassword', payload);
}

export const ForgotpasswordService = (payload) => {
    return POST('users/gateway/trmforgotpassword', payload);
}

export const ResetPasswordService = (payload) => {
    return POST('users/gateway/trmresetlogin', payload);
}

export const CheckResetService = (payload) => {
    return GET('users/gateway/trmcheckresetlink', payload);
}

export const SetPasswordService = (payload) => {
    return POST('users/gateway/trmsetlogin', payload);
}

export const LogoutService = (payload) => {
    return GET('users/csrflogout', payload);
}

export const VerifyPasswordService = (payload) => {
    return POST('users/gateway/trmverifylogin', payload);
}