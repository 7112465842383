import {GET, POST} from './ApiContract';

export const EmailTemplateList = (payload) => {
    return POST('clients/emailtemplates/list', payload);
}

export const EmailAddService = (payload) => {
    return POST('clients/emailtemplates/add', payload);
}

export const EmailTemplateType = (payload) => {
    return GET('clients/emailtemplatetypes/getall', payload);
}

export const EmailVerifiedAccount = (payload) => {
    return GET('clients/emailaccountsettings/broker/verified', payload);
}

export const EditEmailTemplate = (payload) => {
    return POST('clients/emailtemplates/edit', payload);
}
